import { DomManager, Store } from "@/scripts/core";
import components from "@/scripts/components";
import modules from "@/scripts/modules";
import AOS from "aos";

// DOCS: https://github.com/michalsnik/aos
// imported in main.css

/**
 * Set up the initial values and keys for the store. This should be done first
 * thing before mounting and creating the components.
 */
Store.setState({});

// EXTEND:Sprig + Vanilla JS
function init() {
	DomManager.setComponents(components);
	DomManager.setModules(modules);
}

DomManager.define({
	/**
	 * Triggers first and immediately, does not wait for a state. Only
	 * initialize DOM based classes that do not require calculations with
	 * dimensions, because the dimensions of certain elements might not be set
	 * immediately.
	 */
	created() {
		const isReduced =
			window.matchMedia(`(prefers-reduced-motion: reduce)`) === true ||
			window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true;

		if (isReduced) {
			// DON'T use an animation here!
			AOS.init({
				disable: true,
			});
		} else {
			// DO use an animation here!
			AOS.init({
				once: true,
			});
		}
	},

	/**
	 * Triggers on 'interactive' state, if 'interactive' never fires, it is
	 * triggered on 'complete' state. Dimensions are known and set
	 */
	mounted() {
		init();
	},

	// Triggers last and on 'complete' state
	completed() {},
});

