import { Element } from "@/scripts/extends";

export default class Header extends Element {
	mounted() {
		this.isOpen = false;
		this.body = document.body;
		this.trigger = this.el.querySelector(".c-hamburger");
		this.scrollPos = window.scrollY;
	}

	events() {
		window.addEventListener("scroll", () => this.scroll())

		if (!this.trigger) {
			return;
		}

		this.trigger.addEventListener("click", () => this.toggle());
	}

	scroll() {
		this.currentScrollPos = window.scrollY;

		if(this.currentScrollPos > this.scrollPos && this.currentScrollPos > 50) {
			if(!this.el.classList.contains("c-header--hidden")) this.el.classList.add("c-header--hidden")
		} else {
			if(this.el.classList.contains("c-header--hidden")) this.el.classList.remove("c-header--hidden")
		}

		this.scrollPos = this.currentScrollPos
	}

	toggle() {
		if (this.isOpen) {
			this.disable();
		} else {
			this.enable();
		}
	}

	enable() {
		this.el.classList.add("is-open");
		this.body.classList.add("has-scroll-disabled", "has-scroll-disabled--mobile");
		this.isOpen = true;
	}

	disable() {
		this.el.classList.remove("is-open");
		this.body.classList.remove("has-scroll-disabled", "has-scroll-disabled--mobile");
		this.isOpen = false;
	}
}
