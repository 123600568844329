import { Element } from "@/scripts/extends";

export default class Github extends Element {
	mounted() {
		this.url = 'https://api.github.com/repos/TimefoldAI/timefold-solver/releases';
		this.tag = this.el.querySelector(".c-tag__text");

		if (this.tag){
			const cachedRelease = this.getCookie('github_release');

			if (cachedRelease) {
				// Use cached data
				const release = JSON.parse(cachedRelease);
				this.tag.innerText = `${release.tagName} ${release.formattedDate}`;
			} else {
				// Fetch new data from the API
				fetch(this.url, {
					method: "GET",
					headers: {
						"Accept": "application/json",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						let release = data[0];

						if (release) {
							let tagName = release["tag_name"];
							let date = release["published_at"];
							let formattedDate = this.formatDate(date);

							this.tag.innerText = `${tagName} ${formattedDate}`;

							// Store in a cookie
							this.setCookie('github_release', JSON.stringify({ tagName, formattedDate }), 4);
						}
					})
					.catch((error) => {
						console.error(error);
					});
			}
		}
	}

	formatDate(dateString) {
		const date = new Date(dateString);

		const options = { month: 'short', day: 'numeric', year: 'numeric' };
		const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);

		// Get the day with the appropriate suffix
		const day = date.getDate();
		const suffix = (day) => {
			if (day > 3 && day < 21) return 'th'; // covers 11th to 19th
			switch (day % 10) {
				case 1: return 'st';
				case 2: return 'nd';
				case 3: return 'rd';
				default: return 'th';
			}
		};

		return formattedDate.replace(/(\d+)(,)/, `$1${suffix(day)},`);
	}

	setCookie(name, value, hours) {
		const date = new Date();
		date.setTime(date.getTime() + (hours * 60 * 60 * 1000)); // Set expiration time to specified hours
		const expires = "expires=" + date.toUTCString();
		document.cookie = name + "=" + value + ";" + expires + ";path=/";
	}

	getCookie(name) {
		const cname = name + "=";
		const decodedCookie = decodeURIComponent(document.cookie);
		const cookiesArray = decodedCookie.split(';');
		for (let i = 0; i < cookiesArray.length; i++) {
			let cookie = cookiesArray[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(cname) === 0) {
				return cookie.substring(cname.length, cookie.length);
			}
		}
		return null;
	}
}
